.link{
  text-decoration: none;
}
.link:visited, .link:active, .link{
  color: inherit;
}
.link:hover{
  color: #2e4b7a;
}
.header {
    margin-top: 60px;
    padding: 0 15rem;
  }
  
  .header-container{
      display: flex;
      justify-content: center;
      /* flex-wrap: wrap; */
  }
.header-left{
    width: 50%;
    display: flex;
    align-items: center;
    /* border: 1px solid red; */
}
.header-right{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid black; */
}
.header-img{
    width: 100%;
    object-fit: cover;
    /* border: 1px solid blue; */
}
  .headerTitles {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Lora", serif;
    gap: 1rem;
  }
  .header-lg, .header-home-hg {
    font-size: 60px;
    font-weight: 600;
    color: rgb(31, 31, 31);
    line-height: 1.05;
  }
  .header-home-hg{ 
    background: -webkit-linear-gradient(45deg,#3466F6, #7c3aed);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .header-lg, .header-home-hg {
    font-size: 3.5rem;
    font-weight: 600;
  }
  .header-md{
      color: #374151;
      font: 24px;
      font-weight: 200;
  }
  .header-sm{
    color: #1b243d;
    font-size: 19px;
    font-weight: 700;
  }
  .header-btn{
      align-self: flex-start;
      margin-top: 0.5rem;
  }
  .header-btn-txt{
      background:  -webkit-linear-gradient(45deg,#3466F6, #7c3aed);
      color: white;
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0.1rem;
      border-radius: 4rem;
      padding: 0.7rem 1.5rem;
      cursor: pointer;
  }
