.lr-container{
  height: calc(100vh - 80px);
  background: #FAFBFF;
  display: flex;
  padding: 0 8rem;
  justify-content: space-evenly;
  align-items: center;
}
.login {
    /* height: calc(100vh - 80px); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
  }
  
  .loginTitle {
    font-size: 50px;
    color: #3466F6;
  }
  
  .loginForm {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .loginForm > label {
    margin: 10px 0;
  }
  
  .loginInput {
    font-size: 18px;
    
    padding: 15px;
    background-color: white;
    border: 1px solid #3466F6;
    border-radius: 50px;
  }
  
  .loginInput:focus {
    outline: none;
  }
  
  .loginButton {
    align-self: center;
    width: 100%;
    margin-top: 35px;
    font-size: 18px;
    cursor: pointer;
    background-color: #3466F6;
    color: white;
    padding: 15px;
    border: none;
    border-radius: 50px;
    text-align: center;
  }
  
  .loginButton:disabled{
    cursor: not-allowed;
    background-color: lightcoral;
  }
  .loginRegister{
    margin-top: 2rem;
  }
  .loginRegisterColor{
    color: #3466F6;
  }
  .login-img{
    width: 24rem;
    height: auto;
  }