
.sidebar {
    flex: 3;
    height: fit-content;
    margin: 20px;
    padding-bottom: 30px;
    background-color: #FAFBFF;
    border: 1px solid #E7ECF3;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0;
    align-self: flex-start;
    /* border : 1px solid red; */
  }
  .sticky{
    position: sticky;
  }
  .sidebar .topImg{
      display: block;
    border: none;
    top: -5;
  }
  .sidebarItem {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .sidebarTitle {
    margin: 10px;
    padding: 5px;
    width: 80%;
    border-top: solid 1px #a7a4a4;
    border-bottom: solid 1px #a7a4a4;
    text-align: center;
    font-family: "Varela Round", sans-serif;
    font-size: 12px;
    line-height: 19px;
    color: #222222;
    font-weight: 600;
  }
  
  .sidebarItem > img {
    margin-top: 15px;
    width: 250px;
    height: 250px;
  }
  
  .sidebarItem > p {
    padding: 30px;
  }
  
  .sidebarList {
    list-style-type: none;
    margin-bottom: 30px;
  }
  
  .sidebarListItem {
    display: inline-grid;
    grid-template-columns: auto auto auto;
    gap: 1em;
    margin-top: 15px;
    cursor: pointer;

  }
  
  .sidebarSocial {
    margin-top: 15px;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .sidebarIcon {
    font-size: 16px;
    margin-left: 10px;
  }